import Icons from "../../definitions/Icons.js";

export default class ModalDialog extends HTMLElement {
  extraStyles = "";

  connectedCallback() {
    document.body.style.overflow = "hidden";
    this.attachShadow({ mode: "open" });
    this._styles();
    this._template();
    this._listenEvents();
  }

  _template() {
    this.container = document.createElement("div");
    this.style.cssText = `            
            position: fixed;
            z-index: 9999;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background-color: rgba(0,0,0,0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
        `;
    this.container.classList.add("modal");
    this.container.name = "content";
    const html = `    
            <div class="modal-header">
                <h2>${this.dataset.title || ""}</h2>
                <span class="close">${Icons.close}</span>
            </div>
            <div class="modal-content">
            </div>
        `;
    this.container.innerHTML = html;
    this.shadowRoot.appendChild(this.container);
  }

  _styles() {
    const style = document.createElement("style");
    style.innerHTML = `
        h2 {
            margin: 0;
        }

        .modal {
            width: 90%;
            position: relative;
            box-shadow: 0 4px 24px rgba(45, 45, 51, 0.4);
            border-radius: 10px;
            background: white;
            padding: 1rem;
        }

        .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        
        .modal-content {
            margin-top: 1rem;
        }
      
        .close {
          color: black;
          font-size: 28px;
          font-weight: bold;
          display: flex;
          align-items: center;
        }
      
        .close:hover,
        .close:focus {
          color: black;
          text-decoration: none;
          cursor: pointer;
        }

        @media (min-width: 600px) {
            .modal {
                width: 570px;
            }
        }

        ${this.extraStyles}
    `;
    this.shadowRoot.appendChild(style);
  }

  _onIgnore() {
    this.destroy(true);
  }

  _listenEvents() {
    this.shadowRoot
      .querySelector(".close")
      .addEventListener("click", this._onIgnore.bind(this));
  }

  destroy(shouldTriggerOnClose = false) {
    if (this.onClose && shouldTriggerOnClose) {
      this.onClose();
    }
    this.shadowRoot
      .querySelector(".close")
      .removeEventListener("click", this._onIgnore.bind(this));
    this.remove();
    document.body.style.overflow = "unset";
  }
}
