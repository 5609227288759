export default class Icons {
  static arrowRight = `
            <svg viewBox="0 0 6 10" height="20px"><path d="M6 4.99986C5.99413 4.73335 5.9002 4.51898 5.6771 4.30461L1.46771 0.2432C1.30333 0.0809751 1.10959 -0.000137329 0.868885 -0.000137329C0.387476 -0.000137329 0 0.38225 0 0.851543C0 1.08329 0.0939335 1.30345 0.27593 1.47727L3.95695 4.99407L0.27593 8.52246C0.0998043 8.69627 0 8.91064 0 9.14818C0 9.61748 0.387476 9.99986 0.868885 9.99986C1.10372 9.99986 1.30333 9.92454 1.46771 9.75653L5.6771 5.69511C5.9002 5.48074 6 5.26058 6 4.99986Z"></path></svg>
        `;
  static close = `
        <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" fill="black" viewBox="0 0 16 16">
            <path stroke-width="0.5" stroke="black" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
        </svg>
    `;
  static sort = `
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="black" viewBox="0 0 16 16">
                <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659l4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z"></path>
            </svg>
        `;
  static animation = `
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" fill="black" viewBox="0 0 32 16">
                <path fill="black" d="M 3.1980126,0.1164381 C 3.686343,0.37462382 3.872624,0.97958969 3.6140125,1.467433 2.5505026,3.478734 1.9963487,5.719782 2.0000138,7.9945771 c 0,2.3582459 0.5839993,4.5805919 1.6139987,6.5271439 0.6239988,1.177791 -1.1439993,2.113095 -1.7679981,0.935304 C 0.63006289,13.157502 -0.00373506,10.595372 1.6558539e-5,7.9945771 1.6558539e-5,5.3025799 0.66801543,2.760471 1.8460144,0.53212885 2.1043917,0.04416167 2.7098076,-0.14198184 3.1980126,0.1164381 Z m 25.6039744,0 c 0.488205,-0.25841994 1.093621,-0.0722762 1.351998,0.41569075 1.216028,2.29949395 1.849831,4.86164315 1.845998,7.46244825 0.0037,2.6008049 -0.62997,5.1629549 -1.845998,7.4624479 -0.623999,1.177791 -2.391997,0.242487 -1.767998,-0.935304 1.063509,-2.011301 1.617664,-4.252349 1.613998,-6.5271439 0,-2.3582456 -0.583999,-4.5805922 -1.613998,-6.5271441 -0.258612,-0.48784331 -0.07233,-1.09280918 0.416,-1.3509949 z M 6.1140098,3.0662436 C 6.6289706,3.2668843 6.8833757,3.8469266 6.6820092,4.36128 6.2298849,5.519273 5.9985721,6.7515732 6.00001,7.9945771 c 0,1.2830455 0.2399997,2.5081339 0.6799992,3.6332969 0.5774432,1.277927 -1.4159126,2.057544 -1.8599981,0.727459 C 4.2769442,10.965559 3.9988285,9.4865397 4.0000123,7.9945771 c 0,-1.5368566 0.2899998,-3.0077623 0.8199988,-4.3607557 C 5.0208072,3.1203301 5.5998425,2.8663516 6.1140098,3.0662436 Z m 19.7719802,0 c 0.514735,-0.2012169 1.095208,0.052998 1.295999,0.5675778 0.527998,1.3529934 0.817998,2.8238991 0.817998,4.3607557 0,1.5368567 -0.29,3.0077629 -0.819999,4.3607559 -0.521372,1.153835 -2.260961,0.47347 -1.859998,-0.727459 0.44,-1.125163 0.68,-2.3502514 0.68,-3.6332969 0,-1.2830455 -0.24,-2.5081341 -0.68,-3.6332971 -0.20158,-0.5137946 0.05183,-1.0935993 0.566,-1.2950364 z" id="path4" sodipodi:nodetypes="ccccccccccccccccccccccccccsccscc" style="stroke-width:1.99925"></path>
                <rect style="fill:white;fill-opacity:0.550286;stroke:#000000;stroke-width:2.10322;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;paint-order:normal" id="rect837" width="13.356493" height="7.6054311" x="9.1469984" y="4.4016037" ry="2.5064445"></rect>
            </svg>
        `;
  static color = `
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="black" class="bi bi-palette" viewBox="0 0 16 16">
                <path d="M8 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm4 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM5.5 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"></path>
                <path d="M16 8c0 3.15-1.866 2.585-3.567 2.07C11.42 9.763 10.465 9.473 10 10c-.603.683-.475 1.819-.351 2.92C9.826 14.495 9.996 16 8 16a8 8 0 1 1 8-8zm-8 7c.611 0 .654-.171.655-.176.078-.146.124-.464.07-1.119-.014-.168-.037-.37-.061-.591-.052-.464-.112-1.005-.118-1.462-.01-.707.083-1.61.704-2.314.369-.417.845-.578 1.272-.618.404-.038.812.026 1.16.104.343.077.702.186 1.025.284l.028.008c.346.105.658.199.953.266.653.148.904.083.991.024C14.717 9.38 15 9.161 15 8a7 7 0 1 0-7 7z"></path>
            </svg>
        `;
  static trash = `
            <svg width="24" height="24" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path>
            </svg>
        `;
  static thumbnail = `
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M51 65.5c-6 1.9-12.4 7.5-15.8 13.9l-2.7 5.1v343l2.7 5.1c1.5 2.9 4.6 6.8 7 8.8 8.3 7.1-7.4 6.6 215.8 6.4l201.5-.3 4.5-2.3c6.1-3.2 9.7-6.7 12.8-12.7l2.7-5v-343l-2.7-5.1c-3.5-6.6-9.8-12-16.2-13.9-7.3-2.2-402.6-2.1-409.6 0zm389.9 32.3c1.9.9 4.3 3.3 5.3 5.3 1.7 3.4 1.8 9.5 1.6 134.2l-.3 130.6-50.4-54.3c-27.7-29.9-51.6-55.1-53.2-56-3.5-2.1-11.1-2.1-15.2 0-1.8 1-7.7 5.5-13.2 10.2-14.1 12-16.7 13.6-22.4 13.6-2.9 0-6.1-.8-8.2-2-1.9-1.1-17.1-16.8-33.9-34.9-32.7-35.3-34.1-36.4-43.5-36.5-6.9 0-12.1 2.7-18 9.2-2.8 3-32 38.1-65 78l-60 72.4-.3-130.8C64 92.2 63.5 102.1 70.3 98c3.1-2 6.5-2 185.2-2 171.3 0 182.2.1 185.4 1.8z"/><path d="M356.1 129.6c-25.3 6.8-41 33.1-34.6 57.9 6.7 25.6 32.9 41.5 58 35 25.6-6.7 41.5-32.9 35-57.8-6.7-25.9-33.3-41.9-58.4-35.1z"/></svg>
        `;
  static search = `
        <svg viewBox="0 0 16 16" enable-background="new 0 0 24 24">
            <path d="M11.25 10.31C12.14 9.22 12.67 7.84 12.67 6.33C12.67 2.84 9.83 0 6.33 0C2.84 0 0 2.84 0 6.33C0 9.83 2.84 12.67 6.33 12.67C7.84 12.67 9.22 12.14 10.31 11.25C10.77 11.71 14.41 15.35 14.86 15.8C14.99 15.93 15.16 16 15.33 16C15.5 16 15.67 15.93 15.8 15.8C16.07 15.54 16.07 15.12 15.8 14.86C14.89 13.95 11.71 10.77 11.25 10.31ZM1.33 6.33C1.33 3.58 3.58 1.33 6.33 1.33C9.09 1.33 11.33 3.58 11.33 6.33C11.33 9.09 9.09 11.33 6.33 11.33C3.58 11.33 1.33 9.09 1.33 6.33Z">
            </path>
        </svg>
    `;
  static question = `
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-question-circle-fill helpbtn-svg" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"></path>
        </svg>
    `;

  static upload = `
    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><path d="M18,9h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1h2c0.6,0,1,0.4,1,1v7c0,0.6-0.4,1-1,1H6c-0.6,0-1-0.4-1-1v-7c0-0.6,0.4-1,1-1h2  c0.6,0,1-0.4,1-1S8.6,9,8,9H6c-1.7,0-3,1.3-3,3v7c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3v-7C21,10.3,19.7,9,18,9z M9.7,6.7L11,5.4V17  c0,0.6,0.4,1,1,1h0c0.6,0,1-0.4,1-1V5.4l1.3,1.3C14.5,6.9,14.7,7,15,7c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4l-3-3c0,0,0,0,0,0  c-0.4-0.4-1-0.4-1.4,0l-3,3c-0.4,0.4-0.4,1,0,1.4C8.7,7.1,9.3,7.1,9.7,6.7z"/></svg>`;

  static download = `
   <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 15C3 17.8284 3 19.2426 3.87868 20.1213C4.75736 21 6.17157 21 9 21H15C17.8284 21 19.2426 21 20.1213 20.1213C21 19.2426 21 17.8284 21 15" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
<path d="M12 3V16M12 16L16 11.625M12 16L8 11.625" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
</svg>`;

  static drag = `
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path d="M2 11h16v2H2zm0-4h16v2H2zm8 11l3-3H7l3 3zm0-16L7 5h6l-3-3z"/>
        </svg>
    `;

  static fowards = `
    <svg height="18px" width="18px" style="transform: rotate(-45deg);"  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 303.597 303.597" xml:space="preserve">
        <path style="fill:white" d="M57.866,268.881c25.982,19.891,56.887,30.403,89.369,30.402h0.002c6.545,0,13.176-0.44,19.707-1.308
            c39.055-5.187,73.754-25.272,97.702-56.557c14.571-19.033,24.367-41.513,28.329-65.01c0.689-4.084-2.064-7.954-6.148-8.643
            l-19.721-3.326c-1.964-0.33-3.974,0.131-5.595,1.284c-1.621,1.153-2.717,2.902-3.048,4.864
            c-3.019,17.896-10.49,35.032-21.608,49.555c-18.266,23.861-44.73,39.181-74.521,43.137c-4.994,0.664-10.061,1-15.058,1
            c-24.757,0-48.317-8.019-68.137-23.191c-23.86-18.266-39.18-44.73-43.136-74.519c-3.957-29.787,3.924-59.333,22.189-83.194
            c21.441-28.007,54.051-44.069,89.469-44.069c24.886,0,48.484,7.996,68.245,23.122c6.55,5.014,12.43,10.615,17.626,16.754
            l-36.934-6.52c-1.956-0.347-3.973,0.101-5.604,1.241c-1.631,1.141-2.739,2.882-3.085,4.841l-3.477,19.695
            c-0.72,4.079,2.003,7.969,6.081,8.689l88.63,15.647c0.434,0.077,0.869,0.114,1.304,0.114c1.528,0,3.031-0.467,4.301-1.355
            c1.63-1.141,2.739-2.882,3.084-4.841l15.646-88.63c0.721-4.079-2.002-7.969-6.081-8.69l-19.695-3.477
            c-4.085-0.723-7.97,2.003-8.689,6.082l-6.585,37.3c-7.387-9.162-15.87-17.463-25.248-24.642
            c-25.914-19.838-56.86-30.324-89.495-30.324c-46.423,0-89.171,21.063-117.284,57.787C6.454,93.385-3.878,132.123,1.309,171.178
            C6.497,210.236,26.583,244.933,57.866,268.881z"/>
    </svg>
    `;

  static backwards = `
    <svg height="18px" width="18px" style="transform: rotate(225deg) scale(1, -1)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 303.597 303.597" xml:space="preserve">
        <path style="fill:white" d="M57.866,268.881c25.982,19.891,56.887,30.403,89.369,30.402h0.002c6.545,0,13.176-0.44,19.707-1.308
            c39.055-5.187,73.754-25.272,97.702-56.557c14.571-19.033,24.367-41.513,28.329-65.01c0.689-4.084-2.064-7.954-6.148-8.643
            l-19.721-3.326c-1.964-0.33-3.974,0.131-5.595,1.284c-1.621,1.153-2.717,2.902-3.048,4.864
            c-3.019,17.896-10.49,35.032-21.608,49.555c-18.266,23.861-44.73,39.181-74.521,43.137c-4.994,0.664-10.061,1-15.058,1
            c-24.757,0-48.317-8.019-68.137-23.191c-23.86-18.266-39.18-44.73-43.136-74.519c-3.957-29.787,3.924-59.333,22.189-83.194
            c21.441-28.007,54.051-44.069,89.469-44.069c24.886,0,48.484,7.996,68.245,23.122c6.55,5.014,12.43,10.615,17.626,16.754
            l-36.934-6.52c-1.956-0.347-3.973,0.101-5.604,1.241c-1.631,1.141-2.739,2.882-3.085,4.841l-3.477,19.695
            c-0.72,4.079,2.003,7.969,6.081,8.689l88.63,15.647c0.434,0.077,0.869,0.114,1.304,0.114c1.528,0,3.031-0.467,4.301-1.355
            c1.63-1.141,2.739-2.882,3.084-4.841l15.646-88.63c0.721-4.079-2.002-7.969-6.081-8.69l-19.695-3.477
            c-4.085-0.723-7.97,2.003-8.689,6.082l-6.585,37.3c-7.387-9.162-15.87-17.463-25.248-24.642
            c-25.914-19.838-56.86-30.324-89.495-30.324c-46.423,0-89.171,21.063-117.284,57.787C6.454,93.385-3.878,132.123,1.309,171.178
            C6.497,210.236,26.583,244.933,57.866,268.881z"/>
    </svg>
    `;

  static downloadCloud = `<svg
                        xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem"
                        fill="currentColor" class=" bi bi-cloud-download" viewBox="0 0 16 16">
                        <path
                            d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                        <path
                            d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
                    </svg>`;
}
