import QRCodeStyling from "./components/Shared/QRCodeStyling.js";
import PWAPrompt from "./components/Shared/PWAPrompt.js";
import ModalDialogWithButtons from "./components/Shared/ModalDialogWithButtons.js";
import ModalDialog from "./components/Shared/ModalDialog.js";

// Load CDN webcomponent deps
const loadScripts = (srcs) => {
  function loadScript(src) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }
  return Promise.all(srcs.map((src) => loadScript(src)));
};

// Async load cropper. This can be the place to add dependencies related to the web components
loadScripts([
  "https://unpkg.com/qr-code-styling@1.5.0/lib/qr-code-styling.js",
]).then(() => {
  if (!customElements.get("qr-code-styling"))
    customElements.define("qr-code-styling", QRCodeStyling);
});

if (!customElements.get("modal-dialog-with-buttons")) {
  customElements.define("modal-dialog", ModalDialog);
  customElements.define("modal-dialog-with-buttons", ModalDialogWithButtons);
}

if (!customElements.get("pwa-install-prompt")) {
  customElements.define("pwa-install-prompt", PWAPrompt);
}
