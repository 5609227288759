export default class PWAPrompt extends HTMLElement {
  intlInstance = {};
  inputClass = ""; // Optional styles for input
  connectedCallback() {
    this.attachShadow({ mode: "open" });
    this._template();
  }

  hasOneMonthPassed = () => {
    const savedDateStr = localStorage.getItem("pwa_prompt");
    if (!savedDateStr) {
      return true;
    }

    const savedDate = new Date(savedDateStr);
    const currentDate = new Date();
    const diffInMs = currentDate.getTime() - savedDate.getTime();
    return diffInMs >= 2592000000;
  };

  _template() {
    console.log(this.hasOneMonthPassed());
    if (
      window.is_public ||
      window.localStorage.getItem("pwa_prompt") === "installed"
    ) {
      return;
    }
    if (this.hasOneMonthPassed()) {
      // Display confirmation modal
      this.modal = document.createElement("modal-dialog-with-buttons");
      this.modal.title = gettext("Install app");
      this.modal.bodyContent = gettext(
        "Install Atom.bio on your device to get the best experience.",
      );
      const currentDate = new Date();
      this.modal.onAccept = () => {
        this.event.prompt();
        window.localStorage.setItem("pwa_prompt", "installed");
      };
      this.modal.onCancel = () => {
        window.localStorage.setItem("pwa_prompt", currentDate.getTime());
      };
      this.shadowRoot.appendChild(this.modal);
    }
  }
}
