let timeoutId;
export default class AtomSaver {
  constructor() {
    this.userUrl = user_url;
    this.username = username;
    window.addEventListener(
      "beforeunload",
      this._handlePendingRequests.bind(this),
    );
    // this.flushAtomLinks()
  }

  pendingRequest = false;

  static csrftoken = document.querySelector("[name=csrfmiddlewaretoken]").value;

  /**
   * Returns a function that, when called, will only execute the provided function
   * after a specified amount of time has elapsed since the last invocation.
   * @param {Function} func - The function to debounce.
   * @param {number} delay - The delay in milliseconds before the function is invoked.
   * @returns {Function} - The debounced function.
   */
  debounce(func, timeout = 500) {
    // let timeoutId; // Declare timeoutId variable
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  // TODO: Ideally, if atomlinks endpoint works this could be useful
  _typeMapping = {
    CREATE: "PUT",
    UPDATE: "PUT",
    DELETE: "PUT",
  };

  /**
   * Saves payload data asynchronously.
   * @param {Object} options - The options object containing payload and type.
   * @param {Object} options.payload - The payload data to be saved.
   * @param {string} options.type - The type of request (e.g., 'CREATE', 'UPDATE', 'DELETE').
   * @returns {Promise<Object>} A promise that resolves to the saved data upon success, or rejects with an error.
   */
  save({ payload, type, shouldDebounce = true }) {
    const request = (resolve, reject) => {
      return async () => {
        this.togglePreviewLoader(true);
        try {
          const response = await fetch(
            origin + "/boonds_api/" + this.userUrl + "/",
            {
              method: this._typeMapping[type],
              headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": AtomSaver.csrftoken,
              },
              body: JSON.stringify({
                atoms: [payload],
                user_url: this.userUrl,
                username: this.username,
              }),
            },
          );
          const data = await response.json();
          resolve(data.atoms[0]);
        } catch (error) {
          reject(error);
        } finally {
          this.togglePreviewLoader(false);
        }
      };
    };
    return new Promise((resolve, reject) => {
      if (shouldDebounce) {
        const debouncedCall = this.debounce(request(resolve, reject), 400);
        debouncedCall();
        return;
      }
      request(resolve, reject)();
    });
  }

  /**
   * Flushes atoms by updating state and sending a PUT request to the server.
   * @param {Object} state - The state object containing atom links data to be flushed.
   * THIS METHOD IS ONLY FOR DEBUGGING PURPOSES. DO NOT USE IN PRODUCTION.
   */
  async flushAtomLinks(state) {
    try {
      const response = await fetch(
        origin + "/boonds_api/" + this.userUrl + "/",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": AtomSaver.csrftoken,
          },
          body: JSON.stringify({
            atoms: [{ ...state, atom_links: [] }],
            user_url: this.userUrl,
            user: this.username,
          }),
        },
      );
      const result = await response.json();
    } catch (error) {
      console.error("Error saving atom:", error);
    }
  }

  /**
   * Uploads an atom link thumbnail asynchronously.
   * @param {Object} options - The options object containing the thumbnail value and order.
   * @param {File} options.value - The thumbnail file to be uploaded.
   * @param {number} options.order - The order of the atom link.
   * @returns {Promise<string>} A promise that resolves to a string with the url of the uploaded thumbnail upon success.
   */
  async uploadAtomLinkThumbnail({ value, order }) {
    try {
      const formData = new FormData();
      formData.append("atom_link_img", value);
      formData.append("atom_link_order", order);
      formData.append("delete_first", true);
      const response = await fetch(
        origin + "/upload_atom_link_img/" + window.userdata.user_id + "/",
        {
          method: "POST",
          headers: {
            "X-CSRFToken": AtomSaver.csrftoken,
          },
          body: formData,
        },
      );
      return response.text();
    } catch (error) {
      console.error("Error update atom link image:", error);
    }
  }

  /**
   * Uploads a slider item image asynchronously.
   * @param {Object} options - The options object containing the slider item id, image, and remove flag.
   * @param {number} options.sliderItemId - The id of the slider item.
   * @param {File} options.img - The image file to be uploaded.
   * @param {boolean} options.remove - The flag indicating whether the image should be removed.
   * @returns {Promise<string>} A promise that resolves to a string with the url of the uploaded image upon success.
   */
  async updateSliderItemImg({ sliderItemId, img, remove }) {
    try {
      const formData = new FormData();
      formData.append("img", img);
      formData.append("remove", remove);
      const response = await fetch(
        origin + "/slider_item/" + sliderItemId + "/",
        {
          method: "POST",
          headers: {
            "X-CSRFToken": AtomSaver.csrftoken,
          },
          body: formData,
        },
      );
      return response.text();
    } catch (error) {
      console.error("Error update atom link sliderItemImg:", error);
    }
  }

  async uploadOrRemoveAtomLinkFile({ atomLinkId, file, remove }) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("remove", remove);
      const response = await fetch(
        origin + "/atom_link_file/" + atomLinkId + "/",
        {
          method: "POST",
          headers: {
            "X-CSRFToken": AtomSaver.csrftoken,
          },
          body: formData,
        },
      );
      return response.text();
    } catch (error) {
      console.error("Error update atomLinkFileId:", error);
    }
  }

  /**
   * Handles pending requests by preventing default action if sending flag is true.
   * This will prevent the user from leaving the page while a request is being processed with a native popup.
   * @param {Event} event - The event object.
   * @returns {void}
   */
  async _handlePendingRequests(event) {
    if (this.sending) {
      e.preventDefault();
      return;
    }
  }

  // For legacy support
  togglePreviewLoader(value) {
    if (value) {
      $("#savebtneye").css("display", "none");
      $("#loadinggif").css("display", "inline-block");
      return;
    }
    $("#loadinggif").css("display", "none");
    $("#savebtneye").css("display", "inline-block");
  }
}
